import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {PlayerMode} from "../constants/PlayerMode";
import {VIDEO_FRAMES_PER_SECOND} from "../constants/VIDEO_FRAMES_PER_SECOND";
import {secondsToTimeStamp} from "../helpers/SecondsToTimeStamp";
import {getSafeFrame} from "../helpers/GetSafeFrame";
import {runFrameChangedListeners} from "../reducers/RunFrameChangedListeners";

export const videoProgressed = (state: MainVideoState, action: PayloadAction<number>) => {
    console.log('Video progressed', action.payload)
    if (state.playerMode === PlayerMode.WaitingForIdenticalVideoLoad) {
        return;
    }
    const waitingForSeek = state.playerMode === PlayerMode.WaitingForVideoToSeek;
    if (waitingForSeek) {
        state.shouldShowFramePreview = true;
        state.shouldLoadFramePreview = true;
        state.shouldShowMainVideo = false;
        state.shouldUseHighResPreview = true;
        state.playerMode = PlayerMode.Stopped;
    }
    if (state.playerMode === PlayerMode.PlayRequested) {
        state.shouldShowMainVideo = true;
        state.shouldLoadFramePreview = false;
        state.shouldShowFramePreview = false;
    }

    if (!waitingForSeek) {
        state.currentFrame = Math.round(action.payload * VIDEO_FRAMES_PER_SECOND);
        state.videoIsBuffering = false;
        state.currentTimeStamp = secondsToTimeStamp(action.payload);
    }
    if (state.playerMode === PlayerMode.WaitingForPreviewFrameToLoad) {
        const frameToShow = getSafeFrame(state.currentFrame, state.videoDurationInFrames);
        state.lastRelativeMove = undefined;
        state.currentFrameToShowInPreview = frameToShow;
        state.nextFrameToShowInPreview = frameToShow;
        state.currentFrame = frameToShow;
        state.shouldUseHighResPreview = true;
        state.shouldLoadFramePreview = true;
        state.playerMode = PlayerMode.WaitingForPreviewFrameToLoad;
    }

    if (state.desiredPlaybackState === true)
        state.desiredPlaybackState = null;
    state.desiredVideoTime = null;
    runFrameChangedListeners(state);
};

