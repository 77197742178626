import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {goDirectToFrame} from "./GoDirectToFrame";
import {updateButtonsFromState} from "../state-change-side-effects/UpdateButtonsFromState";
import {getDefaultClipForCurrentRun} from "../helpers/GetDefaultClipForCurrentRun";

export const selectProject = (state: MainVideoState, action: PayloadAction<string | null>) => {
    const project = state.projectState.availableProjects.find(p => p.s3Key === action.payload) ?? null;
    if (project === null) {
        state.projectState.currentProject = null;
        state.projectState.selectedRun = null;
        state.projectState.selectedClip = null;
        state.projectState.selectedResolution = null;
        return;
    }

    if(project != null)
        goDirectToFrame(state, 0);

    state.projectState.currentProject = project;
    state.projectState.selectedRun = state.projectState.currentProject.runs[0];
    state.projectState.selectedClip = getDefaultClipForCurrentRun(state);
    state.projectState.selectedResolution = state.projectState.selectedClip.resolutions[0];
    updateButtonsFromState(state);
};
