import React, { FC, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import * as CryptoJS from 'crypto-js';
import { getAppConfig } from "./GetAppConfig";
import { assert } from "../assert";
import { loadProjects } from "../components/ProjectSelector/LoadProjects";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IOauthError, IOauthToken, IOAuthUserInfo } from "../../../common-models/dist";
import GiocondaBrandTopBar from "../components/GiocondaBrandTopBar/GiocondaBrandTopBar";
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Button } from "@mui/material";
import { activeVideoActions } from "../state-slices/ActiveVideo";
import { authActions } from "../state-slices/Auth";
import { GrastUser } from "../data-structures/GrastUser";
import { loadProjectsWithToken } from "./LoadProjectsWithToken";

export const REDIRECT_RETURN_KEY = 'redirect_to';
export const CODE_VERIFIER_KEY = 'last_code';

export const RedirectToLogin: FC = () => {
    const codeVerifier = crypto.randomUUID();
    const codeChallengeHash = base64URL(CryptoJS.SHA256(codeVerifier));
    const [searchParams] = useSearchParams();
    let forceHighResPreview = 'forceHighResPreview';
    if (searchParams.has(forceHighResPreview)) {
        const forceHighRes = searchParams.get(forceHighResPreview) || '0';
        window.localStorage.setItem(forceHighResPreview, forceHighRes);
    }
    const redirect = searchParams.get("redirect") as string;
    if (redirect)
        localStorage.setItem(REDIRECT_RETURN_KEY, redirect);
    else
        localStorage.removeItem(REDIRECT_RETURN_KEY);
    localStorage.setItem(CODE_VERIFIER_KEY, codeVerifier);

    const url = generateLoginUrl(codeChallengeHash);
    assert(url);
    window.location.replace(url);
    return null;
}

export const AuthenticateAndRedirect: FC<AuthenticateAndRedirectProps> = (props: AuthenticateAndRedirectProps) => {
    const dispatch = useAppDispatch();
    const isAuthed = useAppSelector(s => s.auth.isAuthed);
    const userHasNoGroup = useAppSelector(s => s.auth.isAuthed && s.auth.client.groups.length === 0);
    const [authError, setError] = useState(false);
    const [waiverAccepted, setWaiverAccepted] = useState<boolean | null>(null); 

    useEffect(() => {
        // Check if the waiver has been accepted in this session
        const sessionWaiverAccepted = sessionStorage.getItem('waiverAccepted');
        console.log('Initial sessionWaiverAccepted:', sessionWaiverAccepted);
        if (sessionWaiverAccepted === 'true') {
            console.log('Waiver previously accepted, setting waiverAccepted to true');
            setWaiverAccepted(true);
        } else {
            console.log('Waiver not previously accepted, setting waiverAccepted to null');
            setWaiverAccepted(null);
        }
    }, []);

    useEffect(() => {
        console.log('waiverAccepted state:', waiverAccepted);
        if (waiverAccepted === true) {
            console.log('Waiver accepted, storing in sessionStorage');
            sessionStorage.setItem('waiverAccepted', 'true');

            (async () => {
                console.log('Starting authentication process');
                const tokenOrError = await convertCodeToToken(props.code, props.codeVerifier);
                let error = (tokenOrError as IOauthError).error;

                if (error) {
                    console.error('Authentication error:', error);
                    setError(true);
                    return;
                }

                try {
                    console.log('Token', tokenOrError);
                    const token = tokenOrError as IOauthToken;
                    const authConfig = getAppConfig();
                    let userInfoEndpoint = `${authConfig.oauthEndpoint}/userInfo`;
                    console.log('Fetching userinfo from endpoint', userInfoEndpoint);
                    const userInfo = await fetch(userInfoEndpoint, {
                        headers: {
                            authorization: 'Bearer ' + token.access_token
                        }
                    });
                    console.log('Use info fetched from endpoint');

                    const userJson: IOAuthUserInfo = await userInfo.json();
                    console.log('User', userJson);
                    const getProjectsResponse = await loadProjectsWithToken(token.access_token);
                    console.log('Loaded ' + getProjectsResponse.groups.length + ' groups');

                    const customer: GrastUser = {
                        name: userJson.given_name + ' ' + userJson.family_name,
                        groups: getProjectsResponse.groups,
                        accessToken: token.access_token
                    }

                    console.log('Loading project meta data for customer');
                    const projects = await loadProjects(customer.groups);
                    console.log('all data loaded');
                    dispatch(activeVideoActions.customAssetsLoaded(getProjectsResponse.customAssets));
                    dispatch(authActions.userAuthed(customer));
                    dispatch(activeVideoActions.projectsLoaded(projects));
                } catch (e) {
                    console.log('Error while logging in ', e);
                    setError(true);
                }
            })();
        }
    }, [waiverAccepted, dispatch, props.code, props.codeVerifier]);

    if (waiverAccepted === false) {
        return <RedirectToLogin />;
    }

    if (waiverAccepted === null) {
        return (
            <div data-id="waiver-message">
                <GiocondaBrandTopBar />
                <Box sx={{ textAlign: 'center', p: 4 }}>
                <Dialog open={true}>
                        <DialogTitle>Important Notice</DialogTitle>
                        <DialogContent>
                            <p>
                                The railway video footage you are about to access contains sensitive information, including personal data and location data. This footage is strictly for work-related use only. You are reminded of your obligations under contract and/or data protection legislation. This data must be handled with utmost care, ensuring confidentiality and data protection compliance at all times. Any misuse of the data will be treated extremely seriously.
                            </p>
                        </DialogContent>
                        <DialogActions>
                            <Button data-id="accept-waiver" variant="contained" color="primary" onClick={() => setWaiverAccepted(true)}>
                                Accept
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div>
        );
    }

    if (!isAuthed && authError) {
        return <div>
            <GiocondaBrandTopBar />
            <div data-id="auth-failure-message">
                <Alert severity="error">Something went wrong while logging in, please try again. <a href="/" data-id="start-over-link">Start over</a></Alert>
            </div>
        </div>
    }

    let isGoingToAdmin = props.newUrl?.startsWith('/admin');
    if (userHasNoGroup && !isGoingToAdmin) {
        return <div>
            <GiocondaBrandTopBar />
            <div data-id="no-group-message">
                <Alert severity="warning"> No Project has been assigned yet.  Please contact Gioconda <a href="mailto:it@giocondarail.com?subject=Project%20access%20request%20-%20grast.giocondarail.com" target="_blank" rel="noreferrer">it@giocondarail.com</a> to request access. </Alert>
            </div>
        </div>
    }

    return isAuthed ? <Navigate to={props.newUrl || '/projects'} /> : (
        <div>
            <GiocondaBrandTopBar />
            <h2 style={{ textAlign: 'center' }}>Loading...</h2>
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        </div>
    );
};

export const TokenLogin: FC = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code") as string;
    if (!code)
        return null;
    console.log('code', code);
    const codeVerifier = localStorage.getItem(CODE_VERIFIER_KEY);
    assert(codeVerifier, "No stored verifier code");
    const redirect = localStorage.getItem(REDIRECT_RETURN_KEY) ?? null;

    return (
        <AuthenticateAndRedirect code={code} codeVerifier={codeVerifier} newUrl={redirect}></AuthenticateAndRedirect>);
};

interface AuthenticateAndRedirectProps {
    codeVerifier: string;
    code: string;
    newUrl: string | null;
}

async function convertCodeToToken(code: string, codeVerifier: string): Promise<IOauthToken | IOauthError> {
    const authConfig = getAppConfig();
    let tokenEndpoint = `${authConfig.oauthEndpoint}/token`;
    console.log('Fetching token from endpoint', tokenEndpoint);
    const token = await fetch(tokenEndpoint, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: authConfig.clientId,
            redirect_uri: authConfig.returnUrl,
            code: code,
            code_verifier: codeVerifier
        } as any)
    });
    return await token.json();
}

function generateLoginUrl(codeVerifier: string) {
    const baseUrl = getAppConfig().hostedLoginUrl;
    const authEndpoint = `${baseUrl}/authorize`;
    const clientId = getAppConfig().clientId;
    const redirectUrl = getAppConfig().returnUrl;
    const params =
        new URLSearchParams({
            response_type: 'code',
            client_id: clientId,
            redirect_uri: redirectUrl,
            scope: 'openid',
            code_challenge_method: 'S256',
            code_challenge: codeVerifier
        } as any)

    const paramString = params.toString();
    return `${authEndpoint}?${paramString}`;
}

function base64URL(hash: CryptoJS.lib.WordArray): string {
    return hash.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}
