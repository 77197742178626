import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {frameChangeRelative} from "./state-management/reducers/FrameChangeRelative";
import {play} from "./state-management/reducers/Play";
import {stop} from "./state-management/reducers/Stop";
import {videoEnded} from "./state-management/reducers/VideoEnded";
import {videoPlaybackHasStarted} from "./state-management/reducers/VideoPlaybackHasStarted";
import {videoPlaybackHasStopped} from "./state-management/reducers/VideoPlaybackHasStopped";
import {videoProgressed} from "./state-management/state-change-side-effects/VideoProgressed";
import {videoLoaded} from "./state-management/reducers/VideoLoaded";
import {previewFrameLoadComplete} from "./state-management/reducers/PreviewFrameLoadComplete";
import {seekCurrentTimeOnNextVideoLoad} from "./state-management/reducers/SeekCurrentTimeOnNextVideoLoad";
import {locDataLoaded} from "./state-management/reducers/LocDataLoaded";
import {playOrStop} from "./state-management/reducers/PlayOrStop";
import {gpsDataLoaded} from "./state-management/reducers/GpsDataLoaded";
import {startedLoadingLoc} from "./state-management/reducers/StartedLoadingLoc";
import {startedLoadingGps} from "./state-management/reducers/StartedLoadingGps";
import {goDirectToFrame} from "./state-management/reducers/GoDirectToFrame";
import {videoBuffering} from "./state-management/reducers/VideoBuffering";
import {goDirectToMeterage} from "./state-management/reducers/GoDirectToMeterage";
import {toggleGrid} from "./state-management/reducers/ToggleGrid";
import {scrollFinished} from "./state-management/reducers/ScrollFinished";
import {selectProject} from "./state-management/reducers/SelectProject";
import {projectsLoaded} from "./state-management/reducers/ProjectsLoaded";
import {selectRun} from "./state-management/reducers/SelectRun";
import {selectClip} from "./state-management/reducers/SelectClip";
import {selectResolution} from "./state-management/reducers/SelectResolution";
import {assetsLoaded} from "./state-management/reducers/AssetsLoaded";
import {startedLoadingAssets} from "./state-management/reducers/StartedLoadingAssets";
import {setAssetFilter} from "./state-management/reducers/SetAssetFilter";
import {showFullScreen} from "./state-management/reducers/ShowFullScreen";
import {exitFullScreen} from "./state-management/reducers/ExitFullScreen";
import {mouseInactive} from "./state-management/reducers/MouseInactive";
import {mouseActivity} from "./state-management/reducers/MouseActivity";
import {seekVideoToFrame, seekVideoToFrameOnLoad} from "./state-management/reducers/SeekVideoToFrame";
import {previewFrame} from "./state-management/reducers/PreviewFrame";
import {initialState} from "./state-management/InitialState";
import {applyAssetFilters} from "./state-management/state-change-side-effects/ApplyAssetFilters";
import {
    updateRelativeAssetsFromState
} from "./state-management/state-change-side-effects/UpdateRelativeAssetsFromState";
import {AssetCategory} from "../data-structures/AssetCategory";
import {GetProjectsResponseAsset} from '../../../common-models/dist'

const activeVideoSlice = createSlice({
    name: 'activeVideo',
    initialState,
    reducers: {
        previewFrame,
        seekVideoToFrame,
        seekVideoToFrameOnLoad,
        frameChangeRelative,
        play,
        stop,
        videoEnded,
        videoPlaybackHasStarted,
        videoPlaybackHasStopped,
        videoProgressed,
        videoBuffering,
        videoLoaded,
        previewFrameLoadComplete,
        seekCurrentTimeOnNextVideoLoad,
        locDataLoaded,
        playOrStop,
        gpsDataLoaded,
        startedLoadingLoc,
        startedLoadingGps,
        goDirectToFrame: (s,a) => goDirectToFrame(s, a.payload),
        goDirectToFrameAndMeterage: (state,action: PayloadAction<{frame: number, meterage: number}>) => {
            state.locationState.desiredMeterage = action.payload.meterage;
            goDirectToFrame(state, action.payload.frame);
        },
        goToMeterageWithStandback: (state, action: PayloadAction<number>) => {
            const standbackMeterage = action.payload - 10 * state.locationState.meteragePolarity;
            goDirectToMeterage(state, standbackMeterage);
        },
        goDirectToMeterage: (s,a) => goDirectToMeterage(s, a.payload),
        toggleGrid,
        scrollFinished,
        stepForward: (state) => {
            if(!state.locationState.nearestLoc)
                return;
            const nextMeterage = state.locationState.nearestLoc.metre + state.userPreferences.stepDistanceInMeters * state.locationState.meteragePolarity;
            goDirectToMeterage(state, nextMeterage);
        },
        stepBackward: (state) => {
            if(!state.locationState.nearestLoc)
                return;
            const nextMeterage = state.locationState.nearestLoc.metre - state.userPreferences.stepDistanceInMeters * state.locationState.meteragePolarity
            goDirectToMeterage(state, nextMeterage);
        },

        /* Project management actions */
        selectProject,
        projectsLoaded,
        selectRun,
        selectClip,
        selectResolution,
        assetsLoaded,
        customAssetsLoaded: (state, action: PayloadAction<GetProjectsResponseAsset[]>) => {
            state.projectState.customAssets = action.payload;
            applyAssetFilters(state.projectState);
            updateRelativeAssetsFromState(state);
        },
        startedLoadingAssets,
        setAssetFilter,
        showFullScreen,
        exitFullScreen,
        mouseInactive,
        mouseActivity,
        highlightAsset: (state, action: PayloadAction<string>) => {
            state.projectState.highlightedAssetId = action.payload;
            updateRelativeAssetsFromState(state);
        },
        deselectAsset: (state) => {
            state.projectState.highlightedAssetId = undefined;
            updateRelativeAssetsFromState(state);
        },
        selectAssetCategory: (state, action: PayloadAction<AssetCategory>) => {
            state.projectState.viewingAssetCategory = action.payload;
            applyAssetFilters(state.projectState);
            updateRelativeAssetsFromState(state);
        },
        setAssetTextSearch: (state, action: PayloadAction<string>) => {
            state.projectState.assetTextSearch = action.payload;
            applyAssetFilters(state.projectState);
            updateRelativeAssetsFromState(state);
        },
        setAssetPanelState: (state, action: PayloadAction<boolean>) => {
            state.projectState.isAssetPanelOpen = action.payload;
        }
    }
});


const activeVideoActions = activeVideoSlice.actions;
const activeVideoReducer = activeVideoSlice.reducer;
export {
    activeVideoActions
}

export default activeVideoReducer;
