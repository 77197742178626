import {Draft} from "@reduxjs/toolkit";
import {MainVideoState} from "../definitions/MainVideoState";
import {hasVideoEnded} from "../helpers/HasVideoEnded";

export function restartVideoIfEnded(state: Draft<MainVideoState>) {
    let isAtEndOfVideo = hasVideoEnded(state);
    if (isAtEndOfVideo) {
        state.desiredVideoTime = 0;
    }
    return isAtEndOfVideo;
}
