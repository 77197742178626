import {MainVideoState} from "../definitions/MainVideoState";
import {PlayerMode} from "../constants/PlayerMode";
import {getPrecacheFrames} from "../helpers/GetPrecacheFrames";

export const previewFrameLoadComplete = (state: MainVideoState) => {
    if (state.playerMode !== PlayerMode.WaitingForPreviewFrameToLoad) {
        return;
    }
    state.playerMode = PlayerMode.FrameSeeking;
    if (state.nextFrameToShowInPreview &&
        state.currentFrameToShowInPreview !== state.nextFrameToShowInPreview) {
        state.playerMode = PlayerMode.WaitingForPreviewFrameToLoad;
    }
    if (state.nextFrameToShowInPreview)
        state.currentFrameToShowInPreview = state.nextFrameToShowInPreview;
    state.shouldShowFramePreview = true;
    state.shouldShowMainVideo = false;
    const lastRelativeMoveWasByOneFrame =
        !state.lastRelativeMove
        || Math.abs(state.lastRelativeMove) === 1;
    if (state.shouldUseHighResPreview && lastRelativeMoveWasByOneFrame)
        state.framesToCache = getPrecacheFrames(state.currentFrame, state.videoDurationInFrames)
};
