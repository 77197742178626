import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {PlayerMode} from "../constants/PlayerMode";
import {getSafeFrame} from "../helpers/GetSafeFrame";
import {secondsToTimeStamp} from "../helpers/SecondsToTimeStamp";
import {VIDEO_FRAMES_PER_SECOND} from "../constants/VIDEO_FRAMES_PER_SECOND";
import {runFrameChangedListeners} from "./RunFrameChangedListeners";

export const previewFrame = (state: MainVideoState, action: PayloadAction<number>) => {
    if (state.playerMode === PlayerMode.FrameSeeking &&
        state.currentFrameToShowInPreview === action.payload) {
        return;
    }
    const targetFrame = getSafeFrame(action.payload, state.videoDurationInFrames);

    state.shouldUseHighResPreview = false;
    state.shouldLoadFramePreview = true;
    state.currentFrame = targetFrame;
    state.nextFrameToShowInPreview = targetFrame;
    state.desiredPlaybackState = false;
    state.currentTimeStamp = secondsToTimeStamp(state.currentFrame / VIDEO_FRAMES_PER_SECOND);

    runFrameChangedListeners(state);

    if (state.playerMode === PlayerMode.WaitingForPreviewFrameToLoad) {
        return;
    }
    state.playerMode = PlayerMode.WaitingForPreviewFrameToLoad;
    state.currentFrameToShowInPreview = targetFrame;
};
