import React, {FC, useState} from 'react';
import styles from './ToolsCard.module.css';
import {Alert, Card, CardActions, CardContent, Snackbar, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import MapIcon from "@mui/icons-material/Map";
import ShareIcon from "@mui/icons-material/Share";
import {useAppDispatch, useAppSelector} from "../../hooks";
import OsGridRef from 'geodesy/osgridref';
import {assert} from '../../assert';
import {formatFrameUrl} from "../../FormatFrameUrl";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {activeVideoActions} from "../../state-slices/ActiveVideo";
import {screenshotActions} from "../../state-slices/screenshot-dialog/Screenshot";
import {GridReferenceType} from "../../state-slices/state-management/definitions/ProjectInformation";
import {CoordinateTransformer} from "../../state-slices/CoordinateTransformer";

interface ToolsCardProps {}

const ToolsCard: FC<ToolsCardProps> = () => {
    const currentGps = useAppSelector(state => state.activeVideoSlice.locationState.nearestGps);
    const currentProjectSafeName = useAppSelector(state => state.activeVideoSlice.projectState.currentProject?.safeName);
    const currentProject = useAppSelector(state => state.activeVideoSlice.projectState.currentProject);
    const currentRunSafeName = useAppSelector(state => state.activeVideoSlice.projectState.selectedRun?.safeName);
    const currentFrameUrlTemplate = useAppSelector(state => state.activeVideoSlice.projectState.selectedClip?.fullFrameTemplateHref);
    const isPlaying = useAppSelector(state => state.activeVideoSlice.videoIsPlaying);
    const currentClipName = useAppSelector(state => state.activeVideoSlice.projectState.selectedClip?.name);
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    const showingGrid = useAppSelector(state => state.activeVideoSlice.shouldShowGrid);
    const gridButtonEnabled = useAppSelector(state => state.activeVideoSlice.buttonStates.shouldShowGridButton);
    const dispatch = useAppDispatch();
    const [openLinkCopiedSnackbar, setOpenLinkCopiedSnackbar] = useState(false);

    function getWgs84Reference(): [number, number] {
        assert(currentGps);
        assert(currentProject);
        if (currentProject.gridReferenceType === GridReferenceType.OSGB) {
            const gridref = new OsGridRef(currentGps.east, currentGps.north);
            const pWgs84 = gridref.toLatLon();
            return [pWgs84.lat, pWgs84.lon];
        }

        return CoordinateTransformer.convertItmToWgs84(currentGps.east, currentGps.north);
    }

    function openMap() {
        const [latitude, longitude] = getWgs84Reference();
        const mapUrl = `https://www.google.com/maps/place/?t=k&q=${latitude},${longitude}`;
        window.open(mapUrl, '_blank');
    }

    function createShareLink() {
        let linkToCurrentTime = `${window.location.origin}/projects/play/${currentProjectSafeName}/${currentRunSafeName}/${currentClipName}/${currentFrame}`;
        window.navigator.clipboard.writeText(linkToCurrentTime);
        setOpenLinkCopiedSnackbar(true);
    }

    function copySnackbarClosed() {
        setOpenLinkCopiedSnackbar(false);
    }

    function toggleGrid() {
        dispatch(activeVideoActions.toggleGrid());
    }

    function openZoomDialog() {
        assert(currentProject)
        assert(currentFrameUrlTemplate)
        const screenshotImage = formatFrameUrl(currentFrame, currentFrameUrlTemplate, currentProject);
        dispatch(screenshotActions.openScreenshotDialog(screenshotImage))
    }

    return <Card>
        <CardContent>
            <Typography color="textSecondary" gutterBottom>
                Tools
            </Typography>
            <div className={styles.ToolsList}>
                <Button variant="text" data-id="zoom-button" onClick={() => openZoomDialog()} disabled={isPlaying} startIcon={<ZoomInIcon/>}>Zoom</Button>
                { gridButtonEnabled &&
                    <Button variant="text" data-id="show-hide-grid" onClick={() => toggleGrid()} startIcon={<FilterListIcon/>}>Grid {showingGrid ? 'Off' : 'On'}</Button>}
                { currentGps &&
                    <>
                        <Button variant="text" onClick={openMap} data-id="open-map-button" startIcon={<MapIcon/>}>Map</Button>
                    </>}
                <Button variant="text" onClick={createShareLink} data-id="share-link" startIcon={<ShareIcon/>}>Copy link</Button>
            </div>
        </CardContent>
        <CardActions>
        </CardActions>

        <Snackbar
            open={openLinkCopiedSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            onClose={copySnackbarClosed}>
            <Alert data-id="link-copied-alert" onClose={copySnackbarClosed} severity="success" sx={{ width: '100%' }}>
                A link to this video and time has been copied to clipboard.
            </Alert>
        </Snackbar>
    </Card>
};


export default ToolsCard;
