import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {AdminApiBucketProject} from "../../../../common-models/dist";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {FC, useEffect, useState} from "react";

export interface EditProjectDialogProps {
  isOpen: boolean;
  currentProjectS3Paths: string[];
  allProjects: AdminApiBucketProject[];
  onSave: (newProjects: string[]) => void;
  onCancel: () => void;
}

const EditProjectsDialog: FC<EditProjectDialogProps> = (props: EditProjectDialogProps) => {
  const [selectedProjects, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected(props.currentProjectS3Paths);
  }, [props.currentProjectS3Paths]);

  function checkboxChanged(event: React.ChangeEvent<HTMLInputElement>, s3Path: string) {
    const isChecked = event.target.checked;
    if(isChecked) {
      if(isSelected(s3Path)){
        return;
      }
      setSelected([...selectedProjects, s3Path]);
      return;
    }

    setSelected(selectedProjects.filter(s => s !== s3Path));
  }

  function isSelected(s3Path: string) {
    return !!selectedProjects.find(p => p === s3Path);
  }

  return (
    <div>
      <Dialog
        open={props.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Edit projects"}
        </DialogTitle>
        <DialogContent>
          { props.allProjects.map(p => (
          <FormGroup key={p.s3Path}>
            <FormControlLabel control={
              <Checkbox checked={isSelected(p.s3Path)}
                onChange={event => checkboxChanged(event, p.s3Path)}/>}
                              label={p.name} />
          </FormGroup>))}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel}>Cancel</Button>
          <Button onClick={() => props.onSave(selectedProjects)} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditProjectsDialog;
