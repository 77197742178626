import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {FC} from "react";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { AdminApiGrastUser } from "../../../../common-models/dist";

export interface ClientListProps {
  users: AdminApiGrastUser[];
  title?: string;
  showDelete?: boolean;
  showAdd?: boolean;
  showRemove?: boolean;
  onDelete?: (id: string) => void;
  onAdd?: (id: string) => void;
  onRemove?: (id: string) => void;
}

const UserList: FC<ClientListProps> = (props: ClientListProps) => {
  return <div>
    <h1>{ props.title ?? 'Users' }</h1>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Groups</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Enabled</TableCell>
            <TableCell></TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users && props.users.map((user) => (
            <TableRow
              key={user.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {user.firstName} {user.lastName}
              </TableCell>
              <TableCell>
                {user.email}
              </TableCell>
              <TableCell>
                {user.groupNames.join(', ')}
              </TableCell>
              <TableCell>
                {user.status}
              </TableCell>
              <TableCell>
                {user.isEnabled ? 'Enabled' : 'Disabled'}
              </TableCell>
              <TableCell align="right">
                {props.showDelete &&
                <IconButton aria-label="delete" onClick={() => props.onDelete && props.onDelete(user.id)}>
                  <DeleteIcon />
                </IconButton>}
                { props.showAdd && <IconButton aria-label="add" onClick={() => props.onAdd && props.onAdd(user.id)}>
                  <PersonAddIcon />
                </IconButton> }
                { props.showRemove && <IconButton aria-label="remove" onClick={() => props.onRemove && props.onRemove(user.id)}>
                  <PersonRemoveIcon />
                </IconButton> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>;
};

export default UserList;
