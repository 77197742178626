import React, {FC, Fragment, PropsWithChildren} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route, Navigate, useParams
} from "react-router-dom";
import './App.css';
import ProjectPlayer from "./components/ProjectPlayer/ProjectPlayer";
import ProjectSelector from './components/ProjectSelector/ProjectSelector';
import DirectPlayProject from "./components/DirectPlayProject/DirectPlayProject";
import {RedirectToLogin, TokenLogin} from "./auth/TokenLogin";
import {useAppSelector} from "./hooks";
import { assert } from './assert';
import {ConfirmProvider} from "material-ui-confirm";
import AdminHome from "./admin/Components/AdminHome";
declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}
function App() {
  return (
      <ConfirmProvider>
          <Router>
              <Routes>
                  <Route path="/login-complete" element={<TokenLogin />} />
                  <Route path="/login" element={<RedirectToLogin />} />
                  <Route path="/" element={<RedirectToLogin/>} />
                  <Route path="/admin" element={<ProtectedRoute><ConfirmProvider><AdminHome/></ConfirmProvider></ProtectedRoute>} />
                  <Route path="/echo/*" element={<Echo/>} />
                  <Route path="/projects/play/:projectSafeName" element={<ProtectedRoute><ProjectPlayer/></ProtectedRoute>} />
                  <Route path="/projects" element={<ProtectedRoute><ProjectSelector/></ProtectedRoute>}/>
                  <Route path="/projects/play/:safeProjectName/init" element={<ProtectedRoute><DirectPlayProject /></ProtectedRoute>}/>
                  <Route path="/projects/play/:safeProjectName/:runSafeName/:clipName/:frame"
                         element={<ProtectedRoute><DirectPlayProject/></ProtectedRoute>}/>
              </Routes>
          </Router>
      </ConfirmProvider>
  );
}

const ProtectedRoute: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
    console.log('Checking auth')
    const user = useAppSelector(s => s.auth.isAuthed);
    if (!user) {
        const redirectUrl = encodeURI(window.location.pathname);
        console.log('Redirecting for auth', redirectUrl);
        return <Navigate to={`/login?redirect=${redirectUrl}`} replace />;
    }

    return <Fragment>{children}</Fragment>;
};

export const Echo: FC = () => {
    let p = useParams();
    assert(window.origin === 'http://localhost:3000', 'must be on localhost');
    return <div data-id="echo-container">{p['*'] + window.location.search}</div>
}

export default App;
