import ProjectList from "./ProjectList";
import GroupList from "./GroupList";
import {Box, Button, Modal, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {Controller, FieldValues, useForm} from "react-hook-form";
import styles from './Home.module.css'
import EditProjectsDialog from "./EditProjectsDialog";
import UserList from "./UserList";
import {AdminApiBucketProject, AdminApiGrastGroup, AdminApiGrastUser, CreateGroupRequest, AddUserToGroupRequest, DeleteGroupRequest, RemoveUserFromGroupRequest, UpdateGroupRequest} from "../../../../common-models/dist";
import {getAppConfig} from "../../auth/GetAppConfig";
import {useAppSelector} from "../../hooks";
import GiocondaBrandTopBar from "../../components/GiocondaBrandTopBar/GiocondaBrandTopBar";
import {useConfirm} from "material-ui-confirm";

interface NewGroupFormData {
  name: string;
}

const AdminHome = () => {
  const confirm = useConfirm();
  const authToken = useAppSelector(s => s.auth.client.accessToken);

  async function putRequest<TReq>(adminEndpoint: string, input: TReq){
    return await makeRequest<unknown>(adminEndpoint, input);
  }
  async function getRequest<TResp>(adminEndpoint: string){
    return await makeRequest<TResp>(adminEndpoint, {});
  }

  async function makeRequest<T>(adminEndpoint: string, input?: any){
    const response = await fetch(getAppConfig().apiUrl + `/admin/${adminEndpoint}`, {
      headers: {
        authorization: 'Bearer ' + authToken
      },
      body: JSON.stringify(input || {}),
      method: 'POST'
    });

    const responseJson = await response.json();
    return responseJson as T;
  }

  const [groups, setGroups] = useState<AdminApiGrastGroup[]>([]);
  const [projects, setProjects] = useState<AdminApiBucketProject[]>([]);
  const [users, setUsers] = useState<AdminApiGrastUser[]>([]);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);
  const [currentEditingGroup, setCurrentEditingGroup] = useState<AdminApiGrastGroup>({
    id: '',
    name: '',
    projects: [],
    userIds: [],
    s3Path: ''
  });

  async function deleteGroupAndReload(s3Path: string) {

    try{
      await confirm({
        title: 'Delete group',
        description: `Are you sure you want to delete this group?`,
        confirmationText: 'DELETE',
      });
    } catch(e){
      return;
    }

    console.log('deleting group', s3Path);
    await putRequest<DeleteGroupRequest>('delete-group', {
      groupKey: s3Path
    })
    await loadAndSetGroups();
  }

  async function loadAndSetProjects() {
    const loadedProjects = await getRequest<AdminApiBucketProject[]>('get-projects');
    setProjects(loadedProjects)
  }

  async function loadAndSetGroups() {
    const loadedGroups = await getRequest<AdminApiGrastGroup[]>('get-groups');
    console.log('Got groups', loadedGroups);
    setGroups(loadedGroups)
  }

  async function loadAndSetUsers() {
    const loadedUsers = await getRequest<AdminApiGrastUser[]>('get-users');
    setUsers(loadedUsers)
  }

  const loadAll = () => {
    loadAndSetGroups().catch(console.error);
    loadAndSetProjects().catch(console.error);
    loadAndSetUsers().catch(console.error);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadAll, [])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<NewGroupFormData>();

  const onSubmitNewGroup = async (data: FieldValues) => {
    await putRequest<CreateGroupRequest>('create-group', {
      name: data.name
    })
    setOpen(false);
    await loadAndSetGroups();
  };

  function cancelEditing() {
    setEditDialogIsOpen(false);
  }

  async function saveProjectsForGroup(projects: string[]) {
    const group: AdminApiGrastGroup = {
      ...currentEditingGroup,
      projects
    }

    await putRequest<UpdateGroupRequest>('update-group', {
      group
    });
    setEditDialogIsOpen(false);
    await loadAndSetGroups();
  }

  function editGroupProjects(groupId: string) {
    const group = groups.find(c => c.id === groupId);
    if(!group)
      return;
    setCurrentEditingGroup(group);
    setEditDialogIsOpen(true);
  }

  async function addUserToGroup(groupId: string, userId: string) {
    await putRequest<AddUserToGroupRequest>('add-user-to-group', {
      groupId,
      userId
    });
    await loadAndSetGroups();
    await loadAndSetUsers();
  }

  async function removeUserFromGroup(groupId: string, userId: string) {
    console.log('Removing', groupId, userId)
    await putRequest<RemoveUserFromGroupRequest>('remove-user-from-group', {
      groupId,
      userId
    })
    await loadAndSetGroups();
    await loadAndSetUsers();
  }

  return <div>
    <GiocondaBrandTopBar>
      <span style={{flexGrow: 1}}></span>
      <h1 style={{paddingRight: '10px'}}>Admin</h1>
    </GiocondaBrandTopBar>
    <ProjectList projects={projects}/>
    <GroupList
      groups={groups}
      allUsers={users}
      onDeleteGroup={deleteGroupAndReload}
      onEditGroupProjects={editGroupProjects}
      onUserAddedToGroup={addUserToGroup}
      onUserRemovedFromGroup={removeUserFromGroup}
    />
    <UserList users={users} />
    <div className={styles.BottomActions}>
      <Button size="medium" variant="text" onClick={loadAll}>Refresh</Button>
      <Button size="medium" variant="contained" onClick={handleOpen}>Add new group</Button>
    </div>

    <EditProjectsDialog
      allProjects={projects}
      isOpen={editDialogIsOpen}
      currentProjectS3Paths={currentEditingGroup.projects}
      onCancel={cancelEditing}
      onSave={newProjects => saveProjectsForGroup(newProjects)}/>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">

      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create group
        </Typography>
        <div>
          <form onSubmit={handleSubmit(onSubmitNewGroup)}>
            <Controller
              name={"name"}
              control={control}
              defaultValue={''}
              render={({field: {onChange, value}}) => (
                <TextField onChange={onChange} value={value} label={"Group name"}/>
              )}
            />
            {errors.name && <p>Please enter the group name.</p>}
            <Button size="large" variant="contained" type="submit">Save</Button>
          </form>
        </div>
      </Box>
    </Modal>
  </div>
}

export default AdminHome;
