import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {Resolution} from "../constants/Resolution";
import {assert} from "../../../assert";

export const selectResolution = (state: MainVideoState, action: PayloadAction<Resolution>) => {
    assert(state.projectState.selectedClip);
    const foundResolution = state.projectState.selectedClip.resolutions.find(c => c.resolution === action.payload);
    assert(foundResolution);
    state.projectState.selectedResolution = foundResolution;
};
