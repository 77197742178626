import { GetProjectsResponse } from "../../../common-models/dist";
import {getAppConfig} from "./GetAppConfig";

export async function loadProjectsWithToken(token: string) {
    const authConfig = getAppConfig();
    let getProjectsEndpoint = `${authConfig.apiUrl}/get-projects`;
    console.log('Fetching projects from endpoint', getProjectsEndpoint);
    const projectData = await fetch(getProjectsEndpoint, {
        headers: {
            authorization: 'Bearer ' + token
        }
    });

    const getProjectsResponse: GetProjectsResponse = await projectData.json();
    return getProjectsResponse;
}
