import {
    Avatar,
    Divider,
    Fab,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Tab,
    Tabs
} from '@mui/material';
import TrainIcon from '@mui/icons-material/Train';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, {FC, useState} from 'react';
import styles from './AssetList.module.css';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {assert} from "../../assert";
import {getFullUrlFromRelative} from "../../GetFullUrlFromRelative";
import ClearIcon from "@mui/icons-material/Clear";
import AssetFilterDialog from '../AssetFilterDialog/AssetFilterDialog';
import DataPair from "../DataPair/DataPair";
import Button from "@mui/material/Button";
import {activeVideoActions} from "../../state-slices/ActiveVideo";
import {AssetCategory} from "../../data-structures/AssetCategory";
import AssetRecord from "../../data-structures/AssetRecord";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAssetFormDialog from "../CustomAssetFormDialog/CustomAssetFormDialog";
import {PutCustomAssetRequest} from '../../../../common-models/dist';
import {getAppConfig} from "../../auth/GetAppConfig";
import {loadProjectsWithToken} from "../../auth/LoadProjectsWithToken";
import { useConfirm } from "material-ui-confirm";

interface AssetListProps {
}

const AssetList: FC<AssetListProps> = () => {
    const previewFrameHref = useAppSelector(s => s.activeVideoSlice.projectState.selectedClip?.previewFrameTemplateHref);
    const allAssets = useAppSelector(s => s.activeVideoSlice.projectState.assets);
    const filteredAssets = useAppSelector(s => s.activeVideoSlice.projectState.filteredAssets);
    const isFilteringAssets = useAppSelector(s => s.activeVideoSlice.projectState.isFilteringAssets);
    const currentProject = useAppSelector(s => s.activeVideoSlice.projectState.currentProject);
    const currentRun = useAppSelector(s => s.activeVideoSlice.projectState.selectedRun);
    const currentFilters = useAppSelector(s => s.activeVideoSlice.projectState.filterToIncludeAssetTypes);
    const assetCategory = useAppSelector(s => s.activeVideoSlice.projectState.viewingAssetCategory);
    const searchText = useAppSelector(s => s.activeVideoSlice.projectState.assetTextSearch)
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    const currentMeterage = useAppSelector(state => state.activeVideoSlice.locationState.nearestLoc?.metre);
    const accessToken = useAppSelector(state => state.auth.client.accessToken);
    const customAssetsEnabled = !!window.localStorage.getItem('enableCustomAssets')

    const appDispatch = useAppDispatch();
    const confirm = useConfirm();

    const [filterDialogIsOpen, setFilterDialogIsOpen] = useState(false);
    const [customAssetFormDialogIsOpen, setCustomFormDialogIsOpen] = useState(false);
    const [customAssetFormDialogIsLoading, setCustomFormDialogIsLoading] = useState(false);
    const [currentlyEditingAsset, setCurrentlyEditingAsset] = useState(createEmptyNewAsset());

    let assetTypes = Array.from(new Set(allAssets.map(a => a.group)));
    assert(previewFrameHref)
    assert(currentRun);
    assert(currentProject);
    const getAssetImageUrl = (frame: number) => {
        const relativeFramePath = previewFrameHref.replace('%d', frame.toString());
        assert(currentProject);
        return getFullUrlFromRelative(relativeFramePath, currentProject)
    };

    function navigateTo(asset: AssetRecord) {
        appDispatch(activeVideoActions.goToMeterageWithStandback(asset.metre));
    }

    function clearSearch() {
        appDispatch(activeVideoActions.setAssetTextSearch(''));
    }

    function modalComplete(filteredItemTypes: string[]) {
        appDispatch(activeVideoActions.setAssetFilter(filteredItemTypes));
        setFilterDialogIsOpen(false);
    }

    function highlightAsset(id: string) {
        appDispatch(activeVideoActions.highlightAsset(id));
    }

    function changeAssetCategory(event: React.SyntheticEvent, newValue: AssetCategory) {
        appDispatch(activeVideoActions.selectAssetCategory(newValue));
    }

    function setSearchText(value: string) {
        appDispatch(activeVideoActions.setAssetTextSearch(value));
    }

    function deselectAsset() {
        appDispatch(activeVideoActions.deselectAsset());
    }

    function createEmptyNewAsset() {
        assert(currentProject);
        assert(currentRun);
        let currentlyEditingAssetModel: PutCustomAssetRequest = {
            name: '',
            frame: currentFrame,
            id: crypto.randomUUID(),
            meterage: currentMeterage,
            notes: '',
            sharedWithGroupIds: [],
            projectId: currentProject.s3Key,
            runId: currentRun.safeName
        };
        return currentlyEditingAssetModel;
    }


    function createNewAsset() {
        setCustomFormDialogIsLoading(false);
        setCustomFormDialogIsOpen(true);
    }

    async function customAssetFormClosed(asset: PutCustomAssetRequest | undefined) {
        if(!asset) {
            setCustomFormDialogIsOpen(false)
            return;
        }
        console.log('Saving asset', asset);
        setCustomFormDialogIsLoading(true);
        const authConfig = getAppConfig();
        let putAssetEndpoint = `${authConfig.apiUrl}/put-asset`;
        const putAsset = await fetch(putAssetEndpoint, {
            headers: {
                authorization: 'Bearer ' + accessToken
            },
            body: JSON.stringify(asset),
            method: 'POST'
        });

        try {
            await putAsset.text();
            const projectData = await loadProjectsWithToken(accessToken);
            appDispatch(activeVideoActions.customAssetsLoaded(projectData.customAssets));
            setCurrentlyEditingAsset(createEmptyNewAsset());
        } finally {
            setCustomFormDialogIsLoading(false);
            setCustomFormDialogIsOpen(false)
        }
    }

    async function deleteAsset(asset: AssetRecord) {
        const result = await confirm({
            title: 'Delete custom asset',
            description: `Are you sure you want to delete '${asset.name}'?`,
            confirmationText: 'DELETE'
        });

        console.log(result);
    }

    let isViewingCustomAssets = assetCategory === AssetCategory.User;
    return <Paper className={styles.SearchBoxAndList} data-id="asset-list-and-search">
        <Tabs value={assetCategory} onChange={changeAssetCategory} aria-label="Asset category selector" variant="fullWidth" scrollButtons={true}>
            <Tab label="Sighting" value={AssetCategory.Sighting} data-id="sighting-asset-tab"/>
            <Tab label="Assets" value={AssetCategory.KeyAsset} data-id="assets-asset-tab"/>
            <Tab label="Trackside" value={AssetCategory.Trackside} data-id="trackside-asset-tab"/>
            { customAssetsEnabled && <Tab label="Custom" value={AssetCategory.User} data-id="custom-asset-tab"/>}
        </Tabs>

        <Paper style={{marginBottom: 10}} sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>
            <InputBase
                data-id="search-assets"
                sx={{ml: 1, flex: 1}}
                placeholder="Search..."
                inputProps={{'aria-label': 'search google maps'}}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
            />
            {searchText &&
                <IconButton onClick={() => clearSearch()} type="button" sx={{p: '10px'}}
                            data-id="clear-asset-search-button" aria-label="clear search">
                    <ClearIcon/>
                </IconButton>}
            {!searchText &&
                <IconButton data-id="show-filter-dialog" color={isFilteringAssets ? 'warning' : 'default'}
                            onClick={() => setFilterDialogIsOpen(true)} type="button" sx={{p: '10px'}}
                            aria-label="filters">
                    <FilterAltIcon/>
                </IconButton>}
        </Paper>
        <div className={styles.AssetList}>
            {
                filteredAssets.length === 0 &&
                <div className={styles.NoAssetsMessage} data-id="no-assets-found-message">No assets found.</div>
            }

            {filteredAssets.length > 0 &&
                <List dense sx={{width: '100%', bgcolor: 'background.paper'}}>
                    {filteredAssets.map((asset, index) => {
                        return (
                            <div key={asset.asset.id}>
                                {asset.isNextAssetRelativeToTrain &&
                                    <div className={styles.TrainPositionWrapper} data-id="train-position-outer-wrapper">
                                        <div className={styles.TrainPosition}>
                                            <span data-id="train-position-next-asset">{asset.asset.name}</span>
                                            <span style={{flexGrow:1}}></span>
                                            <span style={{paddingRight:"5px"}} data-id="train-position-distance-on-approach">{asset.distanceOnApproach}m</span>
                                            <TrainIcon/>
                                        </div>
                                </div>}
                                <div className={asset.isHighlightedAsset ? styles.SelectedAssetBox : ''}>
                                    {!asset.isHighlightedAsset && <ListItem
                                        data-id={`asset-${index}`}
                                        disablePadding
                                        onClick={() => highlightAsset(asset.asset.id)}>
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar sx={{width: 48, height: 27}}
                                                        variant="square"
                                                        alt={`Asset ${asset}`}
                                                        src={getAssetImageUrl(asset.asset.frame)}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={asset.asset.name} secondary={asset.asset.group}
                                                          data-id="asset-text"/>
                                        </ListItemButton>
                                    </ListItem>}
                                    {asset.isHighlightedAsset &&
                                        <div data-id="selected-asset-panel">
                                            <ListItemButton onClick={() => deselectAsset()} data-id={`asset-${index}`}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{width: 48, height: 27}}
                                                            variant="square"
                                                            alt={`Asset ${asset}`}
                                                            src={getAssetImageUrl(asset.asset.frame)}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText primary={asset.asset.name} secondary={asset.asset.group}
                                                              data-id="asset-text"/>
                                            </ListItemButton>
                                            <Paper>
                                                <div style={{paddingTop: '15px'}}>
                                                    <Divider></Divider>
                                                    <DataPair label="Meterage" value={asset.asset.metre}
                                                              dataId="selected-asset-meterage"></DataPair>
                                                    <Divider/>
                                                    <DataPair label="Distance on Approach" value={asset.distanceOnApproach + 'm'}
                                                              dataId="selected-asset-distance-on-approach"></DataPair>
                                                    { asset.asset.metaData.map(metaDataEntry => (<div key={asset.asset.id + metaDataEntry.name}>
                                                        <Divider/>
                                                        <DataPair label={metaDataEntry.name} value={metaDataEntry.value}
                                                                  dataId={`meta-data-${metaDataEntry.name}`}></DataPair>
                                                    </div>)) }
                                                    <Divider/>
                                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                                        { isViewingCustomAssets && <Button variant="text" data-id="selected-asset-delete" onClick={() => deleteAsset(asset.asset)}>
                                                            <DeleteIcon color={'error'}></DeleteIcon>
                                                        </Button>}
                                                        <span style={{flexGrow: 1}}></span>
                                                        <Button variant="text" data-id="selected-asset-navigate" onClick={() => navigateTo(asset.asset)}>Go</Button>
                                                    </div>
                                                </div>
                                            </Paper>
                                        </div>}
                                </div>
                            </div>
                        )
                    })}
                </List>
            }

            { customAssetsEnabled && isViewingCustomAssets && <div className={styles.AddAssetButton}>
                <Fab onClick={createNewAsset} size="medium" color="warning" aria-label="add" data-id="add-asset-button">
                    <AddIcon />
                </Fab>
            </div> }
            <CustomAssetFormDialog
                isOpen={customAssetFormDialogIsOpen}
                assetModel={currentlyEditingAsset}
                showLoadingSpinner={customAssetFormDialogIsLoading}
                showError={undefined}
                modalComplete={customAssetFormClosed}></CustomAssetFormDialog>
            <AssetFilterDialog
                isOpen={filterDialogIsOpen}
                assetTypes={assetTypes}
                modalComplete={filteredItemTypes => modalComplete(filteredItemTypes)}
                currentFilters={currentFilters || assetTypes}></AssetFilterDialog>
        </div>
    </Paper>
};

export default AssetList;
