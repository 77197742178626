import {assert} from "../assert";
import {IAppConfig} from '../../../common-models/dist'
import Config from '../config/config.json'

let localConfig: IAppConfig = Config;

export function getAppConfig(): IAppConfig {
    const w = (window as any);
    if (w.overrideGrastConfig) {
        const overriddenConfig = JSON.parse(w.overrideGrastConfig) as IAppConfig;
        if (overriddenConfig) {
            assert(overriddenConfig.clientId, 'client id');
            assert(overriddenConfig.returnUrl, 'return url');
            assert(overriddenConfig.oauthEndpoint, 'oauth endpoint');
            assert(overriddenConfig.hostedLoginUrl, 'hostedLoginUrl');
            assert(overriddenConfig.apiUrl, 'api url');
            assert(overriddenConfig.mediaBucketUrl, 'api url');
            return overriddenConfig;
        }
    }

    return localConfig;
}
