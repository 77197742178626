import {AppBar, Dialog, IconButton, Toolbar, Typography} from "@mui/material";
import { FC } from "react";

import CloseIcon from '@mui/icons-material/Close';
import UserList from "./UserList";
import {AdminApiGrastGroup, AdminApiGrastUser} from "../../../../common-models/dist";

interface EditGroupUsersProps {
  isOpen: boolean;
  group: AdminApiGrastGroup;
  handleClose: () => void;
  onUserAddedToGroup(groupId: string, userId: string): void;
  onUserRemovedFromGroup(groupId: string, userId: string): void;
  allUsers: AdminApiGrastUser[]
}

const EditGroupUsersDialog: FC<EditGroupUsersProps> = (props: EditGroupUsersProps) => {
  const usersForThisGroup = props.allUsers.filter(u => props.group.userIds.indexOf(u.id) > -1);
  const usersNotInThisGroup = props.allUsers.filter(u => props.group.userIds.indexOf(u.id) === -1);

  return <div>
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <AppBar sx={{position: 'relative'}}>
        <Toolbar>
          <IconButton
            data-id="close-screenshot-dialog"
            edge="start"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon/>
          </IconButton>
          <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
            Edit users for client
          </Typography>
        </Toolbar>
      </AppBar>
      <div>
        <UserList title={`Members of ${props.group.name}`} users={usersForThisGroup}
                  showRemove={true}
                  onRemove={(userId) => props.onUserRemovedFromGroup(props.group.id, userId)}
        ></UserList>
        <UserList title={'All users'} users={usersNotInThisGroup}
                  showAdd={true}
                  onAdd={(userId) => props.onUserAddedToGroup(props.group.id, userId)} ></UserList>
      </div>
    </Dialog>
  </div>
}

export default EditGroupUsersDialog;
