import React, {FC, PropsWithChildren} from "react";
import styles from "./DataPair.module.css";
export interface DataPairProps extends PropsWithChildren {
    label: string;
    value: string | number | undefined;
    dataId: string;
}

const DataPair: FC<DataPairProps> = (props: DataPairProps) => {
    return <div className={styles.DataPair}>
        <span className={styles.DataLabel}>{props.label}</span>
        <span className={styles.DataValue} data-id={props.dataId}>{props.value}</span>
        {props.children}
    </div>
}


export default DataPair;
