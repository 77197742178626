export function secondsToTimeStamp(seconds: number) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    seconds = seconds - (hours * 3600) - (minutes * 60);

    let outputHours: string = hours.toLocaleString(undefined, {
        minimumIntegerDigits: 2
    });
    let outputMinutes: string = minutes.toLocaleString(undefined, {
        minimumIntegerDigits: 2
    });
    let outputSeconds: string = seconds.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        minimumIntegerDigits: 2
    });

    return `${outputHours}:${outputMinutes}:${outputSeconds}`;
}
