import {Draft} from "@reduxjs/toolkit";
import {MainVideoState} from "../definitions/MainVideoState";
import {safeGetOriginal} from "../helpers/SafeGetOriginal";

export function updateButtonsFromState(state: Draft<MainVideoState>) {
    state.buttonStates.shouldShowPlayButton = !state.videoIsPlaying;
    state.buttonStates.shouldShowStopButton = state.videoIsPlaying;
    state.buttonStates.shouldShowFrameForwardButton = state.currentFrame < state.videoDurationInFrames - 1;
    state.buttonStates.shouldShowFrameBackwardButton = state.currentFrame > 0;

    state.buttonStates.shouldShowGridButton =
        state.projectState.selectedClip?.name === 'RAW' || state.projectState.selectedClip?.name === 'POV';

    if (!state.locationState.nearestLoc) {
        state.buttonStates.shouldShowDistanceStepForwardButton = false;
        state.buttonStates.shouldShowDistanceStepBackwardButton = false;
    }

    if (state.locationState.nearestLoc) {
        let currentMeterage = state.locationState.nearestLoc?.metre ?? 0;
        const nextMeterage = currentMeterage + state.userPreferences.stepDistanceInMeters * state.locationState.meteragePolarity;
        const previousMeterage = currentMeterage - state.userPreferences.stepDistanceInMeters * state.locationState.meteragePolarity;

        let metreMap = state.locationState.metreToLocFast;
        let map = safeGetOriginal(metreMap);

        const nextLoc = map?.get(nextMeterage);
        const previousLoc = map?.get(previousMeterage);
        state.buttonStates.shouldShowDistanceStepForwardButton = !!nextLoc;
        state.buttonStates.shouldShowDistanceStepBackwardButton = !!previousLoc;
    }
}
