import proj4 from "proj4";

export class CoordinateTransformer {
    public static convertItmToWgs84(east: number, north: number): [number, number] {
        let itm = "+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=0.99982 +x_0=600000 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
        let wgs84 = '+proj=longlat +datum=WGS84 +no_defs +type=crs';
        const output = proj4(itm, wgs84, [east, north]);
        return [output[1], output[0]];
    }
}
