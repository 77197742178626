import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {LoadedVideoInformation} from "../definitions/LoadedVideoInformation";
import {VIDEO_FRAMES_PER_SECOND} from "../constants/VIDEO_FRAMES_PER_SECOND";
import {PlayerMode} from "../constants/PlayerMode";
import {updateButtonsFromState} from "../state-change-side-effects/UpdateButtonsFromState";
import {doSeekVideoToFrame} from "../state-manipulators/DoSeekVideoToFrame";

export const videoLoaded = (state: MainVideoState, action: PayloadAction<LoadedVideoInformation>) => {
    state.videoDurationInFrames = action.payload.durationInSeconds * VIDEO_FRAMES_PER_SECOND;
    state.desiredPlaybackState = false;
    state.videoIsPlaying = false;

    state.buttonStates = {
        ...state.buttonStates,
        shouldShowDistanceStepBackwardButton: false,
        shouldShowDistanceStepForwardButton: false,
        shouldShowFrameForwardButton: true,
        shouldShowFrameBackwardButton: false,
        shouldShowPlayButton: true,
        shouldShowStopButton: false
    }

    if (state.playerMode !== PlayerMode.WaitingForPreviewFrameToLoad
        && state.playerMode !== PlayerMode.WaitingForVideoToSeek) {
        state.desiredVideoTime = 0;
    }

    if (state.playerMode === PlayerMode.WaitingForIdenticalVideoLoad) {
        state.desiredVideoTime = (state.currentFrame) / VIDEO_FRAMES_PER_SECOND;
        state.playerMode = PlayerMode.Stopped;
    }

    if(state.frameToSeekToWhenVideoLoads !== null)
        doSeekVideoToFrame(state, state.frameToSeekToWhenVideoLoads);

    updateButtonsFromState(state);
};
