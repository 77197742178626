import {Draft} from "@reduxjs/toolkit";
import {MainVideoState} from "../definitions/MainVideoState";
import {PlayerMode} from "../constants/PlayerMode";
import {VIDEO_FRAMES_PER_SECOND} from "../constants/VIDEO_FRAMES_PER_SECOND";
import {secondsToTimeStamp} from "../helpers/SecondsToTimeStamp";
import {getSafeFrame} from "../helpers/GetSafeFrame";

export function doSeekVideoToFrame(state: Draft<MainVideoState>, frame: number) {
    state.playerMode = PlayerMode.WaitingForVideoToSeek;
    const targetFrame = getSafeFrame(frame, state.videoDurationInFrames);
    state.currentFrame = targetFrame;
    state.currentFrameToShowInPreview = targetFrame;
    const currentTime = state.currentFrame / VIDEO_FRAMES_PER_SECOND;
    state.desiredVideoTime = currentTime;
    state.currentTimeStamp = secondsToTimeStamp(currentTime);
}
