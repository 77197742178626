import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import { createServer, Response } from "miragejs"

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import {getAppConfig} from "./auth/GetAppConfig";
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ((window as any).Cypress) {
    // This initialises the mirage JS server for end to end API interception and mocking
    let otherDomains: string[] = []
    let methods = ["get", "put", "patch", "post", "delete"]

    const server = createServer({
        environment: "test",
        routes() {
            for (const domain of ["/", ...otherDomains]) {
                for (const method of methods) {
                    (this as any)[method](`${domain}*`, async (schema: any, request: any) => {
                        let [status, headers, body] = await (window as any).handleFromCypress(
                            request
                        )
                        return new Response(status, headers, body)
                    })
                }
            }
        }
    });

    server.passthrough(`${getAppConfig().mediaBucketUrl}/**`);
}
