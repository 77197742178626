import {
    AnyAction,
    configureStore,
    createImmutableStateInvariantMiddleware, Dispatch,
    EnhancedStore,
    Middleware
} from '@reduxjs/toolkit'
import {enableMapSet} from 'immer';
import rootReducer from './reducers'
const createStore = () => {
    const initialState = {}
    const store = configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: [
            createImmutableStateInvariantMiddleware({})
        ]
    });
    return store;
}

enableMapSet();
const store = createStore();
export type RootState = ReturnType<typeof store.getState>
export type AppStore = EnhancedStore<RootState, AnyAction, Middleware<{}, any, Dispatch>[]>;
export type AppDispatch = typeof store.dispatch
export {
    createStore
};
export default store;
