import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import LocRecord from "../../../data-structures/LocRecord";
import {buildFastLookupWithDuplicates} from "../helpers/BuildFastLookup";
import {runFrameChangedListeners} from "./RunFrameChangedListeners";

export const locDataLoaded = (state: MainVideoState, action: PayloadAction<LocRecord[]>) => {
    const fastMetreMap = new Map();
    action.payload.forEach(l => fastMetreMap.set(l.metre, l));
    state.locationState.metreToLocFast = fastMetreMap;
    state.locationState.frameToLocFast = buildFastLookupWithDuplicates(action.payload);
    if (action.payload.length > 1){
        const meterageIsIncreasing = action.payload[1].metre > action.payload[0].metre;
        state.locationState.meteragePolarity = meterageIsIncreasing ? 1 : -1;
    }
    runFrameChangedListeners(state);
};
