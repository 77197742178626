import {FrameBasedRecord} from "../definitions/FrameBasedRecord";

export function getBestMatch<T extends FrameBasedRecord>(currentFrame: number, rawLocRecords: T[]): T {
    const currentLoc = rawLocRecords[0];
    const nextLoc = rawLocRecords[1];

    if (!nextLoc)
        return currentLoc;

    if (!currentLoc)
        return nextLoc;

    const distanceToCurrentLoc = Math.abs(currentLoc.frame - currentFrame);
    const distanceToNextLoc = Math.abs(nextLoc.frame - currentFrame);

    if (currentLoc.frame === currentFrame && nextLoc.frame === currentFrame) {
        return currentLoc;
    }

    return distanceToCurrentLoc < distanceToNextLoc
        ? currentLoc
        : nextLoc;
}
