import {MainVideoState} from "../definitions/MainVideoState";
import {populateLocAndGps} from "../state-manipulators/PopulateLocAndGps";
import {updateButtonsFromState} from "./UpdateButtonsFromState";
import {updateRelativeAssetsFromState} from "./UpdateRelativeAssetsFromState";

export const frameChangeListeners: ((state: MainVideoState) => void)[] = [
    populateLocAndGps,
    updateButtonsFromState,
    updateRelativeAssetsFromState
];
