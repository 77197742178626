import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ScreenshotState} from "./ScreenshotState";

const initialState: ScreenshotState = {
    screenshotDialogIsOpen: false,
    zoom: 0.7,
    dy: 0,
    dx: 0,
    imageUrl: '',
    shouldDownloadImage: false
};

const screenshotSlice = createSlice({
    name: 'screenshot',
    initialState,
    reducers: {
        openScreenshotDialog: (state, action: PayloadAction<string>) => {
            state.screenshotDialogIsOpen = true;
            state.imageUrl = action.payload;
        },
        closeDialog: (state) => {
            state.screenshotDialogIsOpen = false;
        },
        setDx: (state, action: PayloadAction<number>) => {
            state.dx = action.payload;
        },
        setDy: (state, action: PayloadAction<number>) => {
            state.dy = action.payload;
        },
        setZoom: (state, action: PayloadAction<number>) =>{
            state.zoom = action.payload;
        },
        increaseZoom: (state) =>{
            state.zoom *= 1.2;
            state.dx *= 1.2;
            state.dy *= 1.2;
        },
        decreaseZoom: (state) =>{
            state.zoom *= .8;
            state.dx *= .8;
            state.dy *= .8;
        },
        reset: (state) =>{
            state.zoom = 0.7;
            state.dy = 0;
            state.dx = 0;
        },
        download: (state) =>{
            state.shouldDownloadImage = true;
        },
        downloadComplete: (state) =>{
            state.shouldDownloadImage = false;
        }
    }
});

const screenshotActions = screenshotSlice.actions;
const screenshotReducer = screenshotSlice.reducer;
export {
    screenshotActions
}

export default screenshotReducer;
