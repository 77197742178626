import React, {FC, SyntheticEvent} from 'react';
import styles from './VideoPlayerControls.module.css';
import Button from "@mui/material/Button";
import {ChevronLeft, ChevronRight, PlayArrow, Stop} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {activeVideoActions} from "../../state-slices/ActiveVideo";
import {Slider} from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ViewListIcon from '@mui/icons-material/ViewList';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

interface VideoPlayerControlsProps {}
const VideoPlayerControls: FC<VideoPlayerControlsProps> = () => {
    const dispatch = useAppDispatch();
    const buttonStates = useAppSelector(state => state.activeVideoSlice.buttonStates);
    const isFullScreen = useAppSelector(state => state.activeVideoSlice.projectState.showFullScreen);
    const isAssetPanelOpen = useAppSelector(state => state.activeVideoSlice.projectState.isAssetPanelOpen);
    const durationInFrames = useAppSelector(state => state.activeVideoSlice.videoDurationInFrames);
    const currentFrame = useAppSelector(state => state.activeVideoSlice.currentFrame);
    const hasAssets = useAppSelector(state => state.activeVideoSlice.projectState.assets.length > 0);

    const onSliderChanged = (event: Event, newValue: number | number[]) => {
        dispatch(activeVideoActions.previewFrame((newValue as number)));
    };

    const onSliderCommit = (event: SyntheticEvent | Event, newValue: number | number[]) => {
        dispatch(activeVideoActions.seekVideoToFrame((newValue as number)));
    };

    const showFullScreen = () => {
        document.documentElement.requestFullscreen();
        dispatch(activeVideoActions.showFullScreen())
    }

    const exitFullScreen = () => {
        document.exitFullscreen();
        dispatch(activeVideoActions.exitFullScreen())
    }

    function showAssets() {
        dispatch(activeVideoActions.setAssetPanelState(true));
    }

    function hideAssets() {
        dispatch(activeVideoActions.setAssetPanelState(false));
    }

    return (
        <div className={styles.VideoPlayerControls}>
            <div className={styles.SeekBar}>
                <Slider data-id="seek-bar"
                        aria-label="seek"
                        color={"warning" as any}
                        min={0}
                        max={durationInFrames - 1}
                        value={currentFrame}
                        disabled={durationInFrames === 0}
                        onChange={onSliderChanged}
                        onChangeCommitted={onSliderCommit}
                />
            </div>
            <div className={styles.ControlContainer}>
                <div className={styles.SideButton}>
                    { hasAssets && <div className={styles.SideButton}>
                        { !isAssetPanelOpen && <Button data-id="open-assets-bottom-button"  color="warning" variant="contained" onClick={showAssets}><ViewListIcon/></Button>}
                        { isAssetPanelOpen && <Button data-id="hide-assets-bottom-button"  color="warning" variant="contained" onClick={hideAssets}><ViewListIcon/></Button>}
                    </div> }
                </div>
                <div className={styles.ControlButtons}>
                    <Button data-id="step-backward" color="warning" variant="contained" disabled={!buttonStates.shouldShowDistanceStepBackwardButton}
                            onClick={() => dispatch(activeVideoActions.stepBackward())}><KeyboardDoubleArrowLeftIcon/></Button>
                    <Button color="warning" variant="contained" data-id="frame-back" disabled={!buttonStates.shouldShowFrameBackwardButton}
                            onClick={() => dispatch(activeVideoActions.frameChangeRelative(-1))}><ChevronLeft/></Button>
                    { buttonStates.shouldShowPlayButton && <Button data-id="play" color="warning" variant="contained" onClick={() => dispatch(activeVideoActions.play())}><PlayArrow/></Button> }
                    { buttonStates.shouldShowStopButton && <Button data-id="stop" color="warning" variant="contained" onClick={() => dispatch(activeVideoActions.stop())}><Stop/></Button> }
                    <Button data-id="frame-forward" color="warning" variant="contained" disabled={!buttonStates.shouldShowFrameForwardButton}
                            onClick={() => dispatch(activeVideoActions.frameChangeRelative(1))}><ChevronRight/></Button>
                    <Button data-id="step-forward" color="warning" variant="contained" disabled={!buttonStates.shouldShowDistanceStepForwardButton}
                            onClick={() => dispatch(activeVideoActions.stepForward())}><KeyboardDoubleArrowRightIcon/></Button>

                </div>
                <div className={styles.SideButton}>
                    { !isFullScreen && <Button data-id="open-full-screen"  color="warning" variant="contained" onClick={showFullScreen}><FullscreenIcon/></Button>}
                    { isFullScreen && <Button data-id="close-full-screen"  color="warning" variant="contained" onClick={exitFullScreen}><FullscreenExitIcon/></Button>}
                </div>
            </div>
        </div>
    )
};
export default VideoPlayerControls;
