import {FrameBasedRecord} from "../definitions/FrameBasedRecord";
import {getBestMatch} from "./GetBestMatch";

export function buildFastLookup<T extends FrameBasedRecord>(records: T[]): Map<number, T> {
    console.time('fast lookup build');
    const fastLookup = new Map<number, T>();
    const rawLocRecords = records.map(r => ({...r, frame: Math.round(r.frame)}));

    let lastLocRecord = rawLocRecords[0];
    for (let i = 1; i < rawLocRecords.length; i++) {
        const currentLoc = rawLocRecords[i];
        for (let interpolatedFrame = lastLocRecord.frame; interpolatedFrame <= currentLoc.frame; interpolatedFrame++) {
            if(fastLookup.has(interpolatedFrame))
                continue;
            fastLookup.set(interpolatedFrame,getBestMatch(interpolatedFrame, [
                lastLocRecord,
                currentLoc
            ]));
        }
        lastLocRecord = currentLoc;
    }

    console.timeEnd('fast lookup build');
    return fastLookup;
}

export function buildFastLookupWithDuplicates<T extends FrameBasedRecord>(records: T[]): Map<number, T[]>{
    const baseLookup = buildFastLookup(records);
    const arrayLookup = new Map<number, T[]>();
    for (const [key, value] of baseLookup.entries()) {
        arrayLookup.set(key, [value]);
    }
    records.forEach(r => arrayLookup.set(r.frame, []));
    records.forEach(r => arrayLookup.get(r.frame)?.push(r));
    return arrayLookup;
}
