import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import AssetRecord from "../../../data-structures/AssetRecord";
import {applyAssetFilters} from "../state-change-side-effects/ApplyAssetFilters";
import {updateRelativeAssetsFromState} from "../state-change-side-effects/UpdateRelativeAssetsFromState";

export const assetsLoaded = (state: MainVideoState, action: PayloadAction<AssetRecord[]>) => {
    state.projectState.assets = action.payload;
    if(action.payload.length === 0)
        state.projectState.isAssetPanelOpen = false;
    applyAssetFilters(state.projectState);
    updateRelativeAssetsFromState(state);
};
