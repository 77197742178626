import {ProjectRun} from "./ProjectRun";

export interface ProjectInformation {
    safeName: string;
    name: string;
    displayName: string;
    gridReferenceType: GridReferenceType;
    runs: ProjectRun[];
    s3Key: string
}

export enum GridReferenceType {
    OSGB = 1,
    ITM = 2
}
