import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {FC, useState} from "react";
import PeopleIcon from '@mui/icons-material/People';
import EditGroupUsersDialog from "./EditGroupUsersDialog";
import {
  AdminApiGrastGroup,
  AdminApiGrastUser } from "../../../../common-models/dist";

class AdminApiNullGrastGroup implements AdminApiGrastGroup {
  id: string = ''
  name: string = ''
  projects: string[] = [];
  s3Path: string = '';
  userIds: string[] = []
}

export interface GroupListProps {
  onUserRemovedFromGroup(groupId: string, userId: string): void;
  onUserAddedToGroup: (groupId: string, userId: string) => void;
  groups: AdminApiGrastGroup[];
  allUsers: AdminApiGrastUser[];
  onDeleteGroup: (groupId: string) => void;
  onEditGroupProjects: (groupId: string) => void;
}
const GroupList: FC<GroupListProps> = (props: GroupListProps) => {
  const [groupUsersDialogOpen, setGroupUsersDialogOpen] = useState<boolean>(false);
  const [editingUsersOfGroup, setEditingUsersOfGroup] = useState<string | null>(null);
  const editingGroup = props.groups.find(c => c.id === editingUsersOfGroup) ?? new AdminApiNullGrastGroup();

  function editGroupProjects(id: string) {
    props.onEditGroupProjects(id);
  }

  function editGroupUsers(id: string) {
    setGroupUsersDialogOpen(true)
    setEditingUsersOfGroup(id);
  }

  return <div>
    <h1>Groups</h1>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Projects</TableCell>
            <TableCell>Users</TableCell>
            <TableCell>Access link</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.groups.map((group) => (
            <TableRow
              key={group.s3Path}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {group.name}
              </TableCell>
              <TableCell>
                {group.projects.length === 0 && <i>No projects</i>}
                {group.projects.join(', ')}
              </TableCell>
              <TableCell>
                {group.userIds?.length}
              </TableCell>
              <TableCell align="right">
                <IconButton aria-label="edit" onClick={() => editGroupProjects(group.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="users" onClick={() => editGroupUsers(group.id)}>
                  <PeopleIcon />
                </IconButton>
                <IconButton onClick={() => props.onDeleteGroup(group.s3Path)} aria-label="delete">
                  <DeleteIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <EditGroupUsersDialog
      isOpen={groupUsersDialogOpen}
      handleClose={() => setGroupUsersDialogOpen(false)}
      group={editingGroup}
      allUsers={props.allUsers}
      onUserAddedToGroup={props.onUserAddedToGroup}
      onUserRemovedFromGroup={props.onUserRemovedFromGroup}
    />
  </div>;
};

export default GroupList;
