import {GrastGroup} from "../../data-structures/GrastGroup";
import {getAppConfig} from "../../auth/GetAppConfig";
import fetchBuilder from 'fetch-retry';
import {ProjectInformation} from "../../state-slices/state-management/definitions/ProjectInformation";
const fetchRetry = fetchBuilder(window.fetch);

export async function loadProjects(groups: GrastGroup[]) {
    const bucketUrl = getAppConfig().mediaBucketUrl;
    const projectsKeys = groups.map(g => g.projects).flat();
    const uniqueProjects = [...new Set(projectsKeys)];

    const projectPromises = uniqueProjects
        .map(async projectKey => {
            const bucketProjectJsonUrl = `${bucketUrl}/${projectKey}project.json?cache_bust=` + crypto.randomUUID();
            const fetchResponse = await fetchRetry(bucketProjectJsonUrl, {
                retries: 3,
                retryDelay: 1000
            });
            const projectJson: ProjectInformation = await fetchResponse.json();
            projectJson.s3Key = projectKey;
            return projectJson;
        });
    const projects = await Promise.all<ProjectInformation>(projectPromises);
    return projects;
}

