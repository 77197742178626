import {
    Box,
    Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    FormControl,
    IconButton, Input, InputLabel, ListItemText, MenuItem, OutlinedInput, Select
} from '@mui/material';
import React, {FC, useState} from 'react';
import styles from './CustomAssetFormDialog.module.css';

import CloseIcon from '@mui/icons-material/Close';
import { PutCustomAssetRequest } from '../../../../common-models/dist';
import DataPair from "../DataPair/DataPair";
import {useAppSelector} from "../../hooks";
import {assert} from "../../assert";
import {formatFrameUrl} from "../../FormatFrameUrl";
interface CustomAssetFormDialogProps {
    isOpen: boolean
    showLoadingSpinner: boolean
    showError: string | undefined
    assetModel: PutCustomAssetRequest
    modalComplete(asset: PutCustomAssetRequest | undefined): void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const CustomAssetFormDialog: FC<CustomAssetFormDialogProps> = (props) => {
    const currentProject = useAppSelector(state => state.activeVideoSlice.projectState.currentProject);
    const currentFrameUrlTemplate = useAppSelector(state => state.activeVideoSlice.projectState.selectedClip?.zoomFrameTemplateHref);
    assert(currentFrameUrlTemplate)
    assert(currentProject)
    const [currentlyEditingAsset, setCurrentlyEditingAsset] = useState<PutCustomAssetRequest>(props.assetModel);
    const screenshotImage = formatFrameUrl(currentlyEditingAsset.frame, currentFrameUrlTemplate, currentProject);

    function closeDialogWithoutSaving() {
        props.modalComplete(undefined);
    }

    function applyClicked() {
        props.modalComplete(currentlyEditingAsset);
    }

    function setGroups() {

    }

    function nameChanged(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setCurrentlyEditingAsset({...currentlyEditingAsset, name: e.target.value});
    }

    return <div className={styles.CustomAssetFormDialog}>
        <Dialog
            open={props.isOpen}
            onClose={() => closeDialogWithoutSaving()}
            aria-labelledby="asset-filter-dialog-title"
            aria-describedby="asset-filter-dialog-description">
            <DialogTitle id="asset-filter-dialog-title" className={styles.DialogTitle}>
                <span>Create new asset</span>
                { !props.showLoadingSpinner && <IconButton onClick={closeDialogWithoutSaving} data-id="close-asset-filters-cross">
                    <CloseIcon />
                </IconButton> }
            </DialogTitle>
            <DialogContent sx={{minHeight: '100px'}}>
                { props.showLoadingSpinner && <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '300px' }}>
                    <CircularProgress />
                </Box> }
                { !props.showLoadingSpinner && <div className={styles.FormContent}>
                    <div className={styles.AssetBox}>
                        <img className={styles.AssetPreviewImage} src={screenshotImage} alt="new asset" />
                        <Divider/>
                        <div>
                            <DataPair label={"Frame"} value={currentlyEditingAsset.frame} dataId={"asset-form-frame"}></DataPair>
                            <DataPair label={"Meterage"} value={currentlyEditingAsset.meterage || 'No meterage'} dataId={"asset-form-meterage"}></DataPair>
                        </div>
                    </div>
                    <FormControl sx={{marginTop: "20px"}}>
                        <InputLabel htmlFor="asset-name">Asset name</InputLabel>
                        <Input id="asset-name" onChange={nameChanged} value={currentlyEditingAsset.name}/>
                    </FormControl>
                    <FormControl sx={{marginTop: "20px"}}>
                        <InputLabel htmlFor="asset-notes">Asset notes</InputLabel>
                        <Input multiline={true} minRows={3} id="asset-nodes" />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="shared-with-groups-label">Share with groups</InputLabel>
                        <Select
                            labelId="shared-with-groups-label"
                            multiple
                            value={currentlyEditingAsset.sharedWithGroupIds}
                            onChange={setGroups}
                            input={<OutlinedInput label="Shared with groups" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}>
                            {names.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={currentlyEditingAsset.sharedWithGroupIds.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div> }
            </DialogContent>
            { !props.showLoadingSpinner && <DialogActions>
                <span style={{'flexGrow': 1}}></span>
                <Button onClick={applyClicked} data-id="apply-asset-types-filter">Save</Button>
            </DialogActions> }
        </Dialog>
    </div>;
};

export default CustomAssetFormDialog;
