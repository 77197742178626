import {MainVideoState} from "../definitions/MainVideoState";
import {restartVideoIfEnded} from "../state-manipulators/RestartVideoIfEnded";
import {hasVideoEnded} from "../helpers/HasVideoEnded";
import {PlayerMode} from "../constants/PlayerMode";
import {doSeekVideoToFrame} from "../state-manipulators/DoSeekVideoToFrame";

export const play = (state: MainVideoState) => {
    console.log('Frame', state.currentFrame, state.videoDurationInFrames);
    restartVideoIfEnded(state);
    const isAtEndOfVideo = hasVideoEnded(state);
    if (!isAtEndOfVideo && state.playerMode === PlayerMode.FrameSeeking) {
        doSeekVideoToFrame(state, state.currentFrameToShowInPreview);
    }
    state.desiredPlaybackState = true;
    state.videoIsPlaying = true;
    state.playerMode = PlayerMode.PlayRequested;
    state.buttonStates.shouldShowStopButton = true;
    state.buttonStates.shouldShowPlayButton = false;
};
