import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {doSeekVideoToFrame} from "../state-manipulators/DoSeekVideoToFrame";

export const seekVideoToFrame = (state: MainVideoState, action: PayloadAction<number>) => {
    doSeekVideoToFrame(state, action.payload);
};

export const seekVideoToFrameOnLoad = (state: MainVideoState, action: PayloadAction<number>) => {
    state.frameToSeekToWhenVideoLoads = action.payload;
};
