import {MainVideoState} from "../definitions/MainVideoState";
import {original} from "@reduxjs/toolkit";
import {goDirectToFrame} from "./GoDirectToFrame";

export const goDirectToMeterage = (state: MainVideoState, desiredMeterage: number) => {
    const loc = original(state.locationState.metreToLocFast)?.get(desiredMeterage);
    if (loc) {
        state.locationState.desiredMeterage = desiredMeterage;
        goDirectToFrame(state, loc.frame);
    }
};
