import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {assert} from "../../../assert";
import {getDefaultClipForCurrentRun} from "../helpers/GetDefaultClipForCurrentRun";
import {goDirectToFrame} from "./GoDirectToFrame";

export const selectRun = (state: MainVideoState, action: PayloadAction<string>) => {
    assert(state.projectState.currentProject);
    const foundRun = state.projectState.currentProject.runs.find(r => r.safeName === action.payload);
    assert(foundRun);
    state.projectState.selectedRun = foundRun;
    state.projectState.selectedClip = getDefaultClipForCurrentRun(state);
    state.projectState.selectedResolution = state.projectState.selectedClip.resolutions[0];
    goDirectToFrame(state, 0);
};
