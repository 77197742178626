import Dialog from '@mui/material/Dialog';
import React, {FC, useEffect, useState} from 'react';
import {Button, DialogTitle, TextField} from "@mui/material";
import styles from './InputDialog.module.css';

interface InputDialogProps {
    onClose: (value: number) => void;
    prompt: string;
    isOpen: boolean;
    fieldLabel: string;
    initialValue: number;
}

const InputDialog: FC<InputDialogProps> = (props) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        setValue(props.initialValue);
    }, [props.initialValue])

    const close = () => {
        props.onClose(value);
    };

    const handleKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            close();
        }
    }

    return <Dialog onClose={() => close()} open={props.isOpen}>
            <DialogTitle>{props.prompt}</DialogTitle>
            <div className={styles.DialogBody}>
                <TextField data-id="input-dialog-value"
                           type="number" label={props.fieldLabel} variant="outlined"
                           value={value} onChange={e => setValue(+e.target.value)}
                           onKeyUp={handleKey}/>
                <Button variant="text" onClick={() => close()} data-id="input-dialog-confirm">Go</Button>
            </div>
        </Dialog>;
};

export default InputDialog;
