import authReducer from "./state-slices/Auth";
import screenshotReducer from "./state-slices/screenshot-dialog/Screenshot";
import activeVideoReducer from "./state-slices/ActiveVideo";

let allReducers = {
    activeVideoSlice: activeVideoReducer,
    screenshotSlice: screenshotReducer,
    auth: authReducer
};
export default allReducers
