import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {PlayerMode} from "../constants/PlayerMode";
import {secondsToTimeStamp} from "../helpers/SecondsToTimeStamp";
import {VIDEO_FRAMES_PER_SECOND} from "../constants/VIDEO_FRAMES_PER_SECOND";
import {runFrameChangedListeners} from "./RunFrameChangedListeners";
import {getSafeFrame} from "../helpers/GetSafeFrame";

export const frameChangeRelative = (state: MainVideoState, action: PayloadAction<number>) => {
    state.shouldUseHighResPreview = true;
    state.playerMode = PlayerMode.WaitingForPreviewFrameToLoad;
    state.shouldLoadFramePreview = true;
    const incrementedFrame = state.currentFrame + action.payload;
    const newFrame = getSafeFrame(incrementedFrame, state.videoDurationInFrames);

    state.currentFrameToShowInPreview = newFrame
    state.nextFrameToShowInPreview = newFrame;
    state.currentFrame = newFrame;
    state.lastRelativeMove = action.payload;
    state.currentTimeStamp = secondsToTimeStamp(newFrame / VIDEO_FRAMES_PER_SECOND);
    runFrameChangedListeners(state);
};
