import {FC, useState} from "react";
import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {AdminApiBucketProject} from "../../../../common-models/dist";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";

export interface ProjectListProps {
  projects: AdminApiBucketProject[];
}

const ProjectList: FC<ProjectListProps> = (props: ProjectListProps) => {
  const [deleteDialogIsOpen, setDeleteDialogState] = useState(false);
  const [deletingProject, setDeletingProject] = useState<AdminApiBucketProject | null>(null);

  function openDeleteDialog(project: AdminApiBucketProject) {
    setDeletingProject(project);
    setDeleteDialogState(true);
  }

  function handleClose() {
    setDeleteDialogState(false);
  }

  return <div>
    <h1>Projects</h1>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>S3 path</TableCell>
            <TableCell>Total storage</TableCell>
            <TableCell>Storage cost p/m</TableCell>
            <TableCell>Date transcoded</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects.map((project) => (
            <TableRow
              key={project.s3Path}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {project.name}
              </TableCell>
              <TableCell>
                {project.s3Path}
              </TableCell>
              { project.projectFileMissing && <>
                <TableCell colSpan={3}>{project.projectFileMissing ? 'Project file missing' : ''}</TableCell>
              </> }
              {!project.projectFileMissing && <>
                <TableCell>{Math.round(project.projectSizeInMegabytes/1024)}GB</TableCell>
                <TableCell>${project.baseCost.toLocaleString(undefined, {maximumFractionDigits: 1})}</TableCell>
                <TableCell>{new Date(project.dateTranscoded).toLocaleString()}</TableCell>
              </>}
              <TableCell align="right">
                <IconButton onClick={() => openDeleteDialog(project)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog onClose={handleClose} open={deleteDialogIsOpen}>
      <DialogTitle>Delete project</DialogTitle>
      <div>
        To delete the project run the following command
        <pre>
          aws s3 rm --recursive --region= --profile=grast_production s3://TODO/{deletingProject?.s3Path}
        </pre>
      </div>
    </Dialog>
  </div>;
}

export default ProjectList;
