import {MainVideoState} from "../definitions/MainVideoState";
import {PlayerMode} from "../constants/PlayerMode";
import {doSeekVideoToFrame} from "../state-manipulators/DoSeekVideoToFrame";
import {restartVideoIfEnded} from "../state-manipulators/RestartVideoIfEnded";
import {updateButtonsFromState} from "../state-change-side-effects/UpdateButtonsFromState";

export const playOrStop = (state: MainVideoState) => {
    if (state.playerMode === PlayerMode.FrameSeeking) {
        doSeekVideoToFrame(state, state.currentFrameToShowInPreview);
    }
    restartVideoIfEnded(state);
    state.playerMode = PlayerMode.PlayRequested;
    state.desiredPlaybackState = !state.videoIsPlaying;
    state.videoIsPlaying = state.desiredPlaybackState;
    updateButtonsFromState(state);
};
