import {PlayerMode} from "./constants/PlayerMode";
import GpsRecord from "../../data-structures/GpsRecord";
import LocRecord from "../../data-structures/LocRecord";
import {MainVideoState} from "./definitions/MainVideoState";
import {AssetCategory} from "../../data-structures/AssetCategory";

export const initialState = {
    frameToSeekToWhenVideoLoads: null,
    currentFrame: 0,
    currentFrameToShowInPreview: 0,
    desiredPlaybackState: false,
    videoIsPlaying: false,
    videoDurationInFrames: 0,
    shouldShowFramePreview: false,
    currentVideoUrl: '',
    shouldShowMainVideo: true,
    shouldLoadFramePreview: false,
    desiredVideoTime: 0,
    playerMode: PlayerMode.Stopped,
    framesToCache: Array<number>(),
    shouldShowGrid: false,
    shouldUseHighResPreview: false,
    currentTimeStamp: "00:00:00.00",
    videoIsBuffering: false,
    lastRelativeMove: undefined,
    nextFrameToShowInPreview: 0,
    assets: [],
    buttonStates: {
        shouldShowDistanceStepBackwardButton: false,
        shouldShowDistanceStepForwardButton: false,
        shouldShowFrameBackwardButton: false,
        shouldShowFrameForwardButton: false,
        shouldShowPlayButton: false,
        shouldShowStopButton: false,
        shouldShowGridButton: false
    },
    projectState: {
        showFullScreen: false,
        showFullScreenControlBar: false,
        availableProjects: [],
        currentProject: null,
        selectedRun: null,
        selectedClip: null,
        selectedResolution: null,
        assets: [],
        filteredAssets: [],
        customAssets: [],
        filterToIncludeAssetTypes: undefined,
        isFilteringAssets: false,
        isAssetPanelOpen: false,
        highlightedAssetId: undefined,
        viewingAssetCategory: AssetCategory.Sighting,
        assetTextSearch: ''
    },
    locationState: {
        desiredMeterage: 0,
        nearestLoc: undefined,
        nearestGps: undefined,
        frameToGpsFast: new Map<number, GpsRecord>(),
        frameToLocFast: new Map<number, LocRecord[]>(),
        metreToLocFast: new Map<number, LocRecord>(),
        meteragePolarity: 1
    },
    userPreferences: {
        stepDistanceInMeters: 5
    }
} as MainVideoState
