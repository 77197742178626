import {MainVideoState} from "../definitions/MainVideoState";
import {assert} from "../../../assert";

export function getDefaultClipForCurrentRun(state: MainVideoState) {
    let selectedRun = state.projectState.selectedRun;
    assert(selectedRun);
    let defaultClipForRun =
        selectedRun.availableClips.find(c => c.name === 'RAW')
        || selectedRun.availableClips[0];
    return defaultClipForRun;
}
