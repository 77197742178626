import {MainVideoState} from "../definitions/MainVideoState";

export const updateRelativeAssetsFromState = (state: MainVideoState) => {
    state.projectState.filteredAssets.forEach(a => a.isNextAssetRelativeToTrain = false);
    const isIncreasingMeterage = state.locationState.meteragePolarity === 1;

    for (const asset of state.projectState.filteredAssets) {
        let thisAssetIsTheHighlightedAsset = asset.asset.id === state.projectState.highlightedAssetId;
        asset.isHighlightedAsset = thisAssetIsTheHighlightedAsset;
    }

    const currentMeterage = state.locationState.nearestLoc?.metre;
    if (currentMeterage === undefined) {
        return;
    }

    for (const asset of state.projectState.filteredAssets) {
        if (asset.asset.metre >= currentMeterage && isIncreasingMeterage){
            asset.isNextAssetRelativeToTrain = true;
            break;
        }

        if (asset.asset.metre <= currentMeterage && !isIncreasingMeterage){
            asset.isNextAssetRelativeToTrain = true;
            break;
        }
    }

    for (const asset of state.projectState.filteredAssets) {
        asset.distanceOnApproach = (asset.asset.metre - currentMeterage) * state.locationState.meteragePolarity;
        if(asset.asset.id === state.projectState.highlightedAssetId)
            asset.isHighlightedAsset = true;
    }
}
