import {AssetRelativeToTrain, ProjectState} from "../ProjectState";
import AssetRecord from "../../../data-structures/AssetRecord";
import {AssetCategory} from "../../../data-structures/AssetCategory";

export function applyAssetFilters(state: ProjectState) {

    function assetRelativeToTrain(asset: AssetRecord): AssetRelativeToTrain {
        return {
            asset,
            isHighlightedAsset: false,
            distanceOnApproach: 0,
            isNextAssetRelativeToTrain: false
        };
    }

    const customAssets = state.customAssets
        .filter(a => a.projectId === state.currentProject?.s3Key)
        .filter(a => a.runId === state.selectedRun?.safeName)
        .map(customAsset => {
       const mapped: AssetRecord = {
           name: customAsset.name,
           id: customAsset.id,
           assetCode: '',
           frame: customAsset.frame,
           metre: customAsset.meterage || 0,
           category: AssetCategory.User,
           group: '',
           metaData: []
       }
       return mapped;
    });
    customAssets.sort((a, b) => {
        return a.frame - b.frame;
    })
    const allAssets = [...state.assets, ...customAssets];

    const assetsInCategory = allAssets.filter(a => a.category === state.viewingAssetCategory)
        .filter(a => a.name.toLowerCase().indexOf(state.assetTextSearch.toLowerCase()) > -1);

    let filters = state.filterToIncludeAssetTypes;
    if (filters === undefined) {
        state.filteredAssets = assetsInCategory.map(assetRelativeToTrain);
        state.isFilteringAssets = false;
        return;
    }

    state.filteredAssets = assetsInCategory.filter(a => filters && filters.indexOf(a.group) > -1)
        .map(assetRelativeToTrain);
    state.isFilteringAssets = state.filteredAssets.length !== state.assets.length;
}
