import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {assert} from "../../../assert";
import {updateButtonsFromState} from "../state-change-side-effects/UpdateButtonsFromState";
import {PlayerMode} from "../constants/PlayerMode";
import {doSeekVideoToFrame} from "../state-manipulators/DoSeekVideoToFrame";

export const selectClip = (state: MainVideoState, action: PayloadAction<string>) => {
    assert(state.projectState.selectedRun);
    const foundClip = state.projectState.selectedRun.availableClips.find(c => c.name === action.payload);
    assert(foundClip);
    doSeekVideoToFrame(state, state.currentFrame);
    state.playerMode = PlayerMode.WaitingForIdenticalVideoLoad;
    state.projectState.selectedClip = foundClip;
    state.projectState.selectedResolution = state.projectState.selectedClip.resolutions[0];
    updateButtonsFromState(state);
};
