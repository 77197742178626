import {MainVideoState} from "../definitions/MainVideoState";
import {safeGetOriginal} from "../helpers/SafeGetOriginal";

export function populateLocAndGps(state: MainVideoState) {
    if (state.locationState.frameToLocFast) {
        console.time('Frame loc lookup')
        let frameToLocMap = safeGetOriginal(state.locationState.frameToLocFast);
        let allMatchingLocs = frameToLocMap.get(state.currentFrame);
        state.locationState.nearestLoc = undefined;
        if(allMatchingLocs){
            let firstLoc = allMatchingLocs[0];
            state.locationState.nearestLoc = firstLoc;

            if (state.locationState.desiredMeterage) {
                const foundPreferredLoc = allMatchingLocs.find(l => l.metre === state.locationState.desiredMeterage);
                if(foundPreferredLoc){
                    state.locationState.nearestLoc = foundPreferredLoc;
                }
            }
        }
        console.timeEnd('Frame loc lookup')
    }

    if (state.locationState.frameToGpsFast) {
        state.locationState.nearestGps = safeGetOriginal(state.locationState.frameToGpsFast)?.get(state.currentFrame);
    }
}
