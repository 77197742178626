import {MainVideoState} from "../definitions/MainVideoState";
import {PayloadAction} from "@reduxjs/toolkit";
import {applyAssetFilters} from "../state-change-side-effects/ApplyAssetFilters";
import {updateRelativeAssetsFromState} from "../state-change-side-effects/UpdateRelativeAssetsFromState";

export const setAssetFilter = (state: MainVideoState, newFilterTypesAction: PayloadAction<string[]>) => {
    state.projectState.filterToIncludeAssetTypes = newFilterTypesAction.payload;
    const hasSelectedAllAssetTypes = state.projectState.assets
        .filter(a => newFilterTypesAction.payload.indexOf(a.group) === -1)
        .length === 0;
    if (hasSelectedAllAssetTypes)
        state.projectState.filterToIncludeAssetTypes = undefined;
    applyAssetFilters(state.projectState);
    updateRelativeAssetsFromState(state);
};
