import {MainVideoState} from "../definitions/MainVideoState";
import {getSafeFrame} from "../helpers/GetSafeFrame";
import {PlayerMode} from "../constants/PlayerMode";
import {runFrameChangedListeners} from "./RunFrameChangedListeners";

export const videoEnded = (state: MainVideoState) => {
    state.desiredPlaybackState = false;
    state.videoIsPlaying = false;
    const frameToShow = getSafeFrame(state.videoDurationInFrames, state.videoDurationInFrames);
    state.lastRelativeMove = undefined;
    state.currentFrameToShowInPreview = frameToShow;
    state.nextFrameToShowInPreview = frameToShow;
    state.currentFrame = frameToShow;
    state.shouldUseHighResPreview = true;
    state.shouldLoadFramePreview = true;
    state.playerMode = PlayerMode.WaitingForPreviewFrameToLoad;
    runFrameChangedListeners(state);
};
