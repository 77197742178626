import React, { FC } from 'react';
import styles from './GiocondaBrandTopBar.module.css';

interface GiocondaBrandTopBarProps {
    children?: React.ReactNode
}

const GiocondaBrandTopBar: FC<GiocondaBrandTopBarProps> = (props) => (
  <div className={styles.TopBar}>
      <div className={styles.Logo}>
          <img src="/logo.png" alt="logo"/>
      </div>
      {props.children}
  </div>
);

export default GiocondaBrandTopBar;
